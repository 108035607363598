import React from 'react'
import FavProductCard from '../components/FavProductCard';
import Favorites  from '../components/myAccount/Favorites'

const homepagefavorites = () => {
  return (
    <div>
        <Favorites/>
 
    </div>
  )
}

export default homepagefavorites